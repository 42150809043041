import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../layouts"
import Seo from "../components/seo"
import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  MarkdownBody,
  MarkdownContent,
} from "../components/Section"
import {
  HeroContainer,
  HeroStatic,
  HeroCaption,
  HeroTitle,
  HeroDescription,
  HeroMedia,
} from "../components/Hero"
import ExpertSection from "../components/Expert"
import ProductCard from "../components/ProductCard"
import PrimaryButton from "../components/Button/PrimaryButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import RequestModal from "../components/RequestModal"

const HeroActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -22px;
  ${BreakpointUp.lg` 
        margin-top: -26px;
    `}
  > a {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
    .btn {
      width: 100%;
    }
  }
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const CityTemplatePage = ({ location, data }) => {
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)

  // const HandleModalOpen = () => {
  //   setIsQuoteVisible(true)
  //   document.querySelector("body").classList.add("modal-open")
  // }

  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title={data.contentfulCity.heroTitle}
        description={data.contentfulCity.heroDescription.heroDescription}
      />
      <Section
        xpt="114px"
        mpt="94px"
        pt="64px"
        xpb="0"
        mpb="0"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <HeroContainer bgColor="transparent">
            <HeroStatic
              bgAfterBefore="none"
              pt="30px"
              xpb="90px"
              mpb="70px"
              pb="50px"
            >
              <HeroCaption>
                <BreadCrumb position="static" translate="0">
                  <Link to="/">Home</Link>
                  <Link to={data.contentfulCity.state.url}>
                    {data.contentfulCity.state.name}
                  </Link>
                  <span>{data.contentfulCity.name}</span>
                </BreadCrumb>
                <HeroTitle color="#000">
                  {data.contentfulCity.heroTitle}
                </HeroTitle>
                <HeroDescription maxWidth="1496px" color="#666">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulCity.heroDescription.childMarkdownRemark
                          .html,
                    }}
                  />
                </HeroDescription>
              </HeroCaption>
              <HeroMedia>
                <GatsbyImage
                  image={getImage(data.contentfulCity.heroImage)}
                  alt={data.contentfulCity.heroTitle}
                />
                <HeroActions>
                  <a href="tel:8004407309" aria-label="Phone">
                    <PrimaryButton
                      textAfter="(800) 440-7309"
                      icon={<PhoneIcon />}
                      size="lg"
                    />
                  </a>
                </HeroActions>
              </HeroMedia>
            </HeroStatic>
          </HeroContainer>
          <hr />
        </Container>
      </Section>

      <Section
        xpt="90px"
        mpt="60px"
        pt="30px"
        xpb="30px"
        mpb="10px"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle>{data.contentfulCity.productSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1496px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulCity.productsSectionDescription
                    .childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <SectionGrid>
            {data.allContentfulProduct.edges.map((item, i) => {
              if (i < 3) {
                return (
                  <SectionGridCol
                    key={`${data.contentfulCity.name}product-${i}`}
                  >
                    <ProductCard
                      data={item.node}
                      openQuoteModal={HandleModalClose}
                    />
                  </SectionGridCol>
                )
              }
              return <></>
            })}
          </SectionGrid>
        </Container>
      </Section>

      <ExpertSection />

      <Section
        xpt="60px"
        mpt="40px"
        pt="30px"
        xpb="60px"
        mpb="40px"
        pb="30px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MarkdownBody>
            <MarkdownContent maxWidth="1496px">
              <div dangerouslySetInnerHTML={{__html: data.contentfulCity.content.childMarkdownRemark.html}} />
            </MarkdownContent>
          </MarkdownBody>
        </Container>
      </Section>
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default CityTemplatePage

export const query = graphql`
  query CityTemplatePageQuery($id: String!) {
    contentfulCity(id: { eq: $id }) {
      id
      name
      heroTitle
      heroDescription {
        heroDescription
        childMarkdownRemark {
          html
        }
      }
      state {
        name
        url
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      productsSectionTitle
      productsSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
